"use client";

import * as stylex from "@stylexjs/stylex";

import {
  colors,
  colorsRaw,
  radius,
  semanticColors,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import type { CSSColorStrict } from "../../../../utils";
import type { AvatarProps } from "../Avatar";
import { Avatar } from "../Avatar";
import { Text } from "../Text";

const styles = stylex.create({
  avatarFull: {
    height: spacing.full,
    width: spacing.full,
  },
  avatarXXL: {
    position: "relative",
  },
  base: (props: { color: CSSColorStrict }) => ({
    backgroundColor: props.color,
    display: "flex",
    justifyContent: "center",
  }),
  bottomRightAvatar: {
    bottom: 0,
    right: 0,
  },
  sideAvatar: (props: { color: CSSColorStrict }) => ({
    outline: `2px solid ${props.color}`,
    position: "absolute",
  }),
  topLeftAvatar: {
    left: 0,
    top: 0,
  },
  infoContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: spacing.s,
  },
  labelText: {
    color: colors.white,
  },
  subLabelText: {
    color: semanticColors.onColorVeryHigh,
  },
});

const stylesSize = stylex.create({
  xs: {
    borderRadius: 6,
    height: spacing.l,
    padding: spacing.t,
    width: spacing.l,
  },
  m: {
    borderRadius: radius.ms,
    height: spacing.xxxl,
    padding: spacing.xs,
    width: spacing.xxxl,
  },
  xl: {
    borderRadius: "20px",
    height: "116px",
    padding: spacing.ms,
    width: "116px",
  },
  xxl: {
    alignItems: "center",
    borderRadius: radius.m,
    flexDirection: "column",
    gap: spacing.m,
    height: "200px",
    padding: `${spacing.l} ${spacing.m}`,
    width: "160px",
  },
});

export type AvatarCommunityProps = Omit<AvatarProps, "size"> & {
  color?: CSSColorStrict;
  size?: "xs" | "m" | "xl" | "xxl";
  topLeftImgSrc?: string;
  bottomRightImgSrc?: string;
  sublabel?: string;
};

export const AvatarCommunity = ({
  color,
  size = "m",
  topLeftImgSrc,
  bottomRightImgSrc,
  styleXArray = [],
  ...props
}: AvatarCommunityProps) => {
  const communityColor = color ?? (colorsRaw.black as CSSColorStrict);
  return (
    <div
      {...stylex.props(
        styles.base({ color: communityColor }),
        stylesSize[size],
        styleXArray,
      )}
    >
      <Avatar
        size={size === "xxl" ? 96 : "full"}
        styleXArray={[size === "xxl" ? styles.avatarXXL : styles.avatarFull]}
        {...props}
      >
        {size === "xxl" && (
          <>
            {topLeftImgSrc && (
              <Avatar
                size={32}
                imgSrc={topLeftImgSrc}
                styleXArray={[
                  styles.sideAvatar({ color: communityColor }),
                  styles.topLeftAvatar,
                ]}
              />
            )}
            {bottomRightImgSrc && (
              <Avatar
                size={32}
                imgSrc={bottomRightImgSrc}
                styleXArray={[
                  styles.sideAvatar({ color: communityColor }),
                  styles.bottomRightAvatar,
                ]}
              />
            )}
          </>
        )}
      </Avatar>
      {size === "xxl" && (
        <div {...stylex.props(styles.infoContainer)}>
          {props.name && (
            <Text type="title" size="m" styleXArray={[styles.labelText]}>
              {props.name}
            </Text>
          )}
          {props.sublabel && (
            <Text type="detail" size="l" styleXArray={[styles.subLabelText]}>
              {props.sublabel}
            </Text>
          )}
        </div>
      )}
    </div>
  );
};
