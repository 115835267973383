"use client";

import type { AnalyticsEvent } from "@packages/analytics";
import { useAnalytics } from "@packages/analytics";
import { I18nMessageClient } from "@packages/i18n";
import { usePathMatch } from "@packages/sdk";
import * as stylex from "@stylexjs/stylex";
import type { LinkProps } from "next/link";
import Link from "next/link";
import type { ReactNode } from "react";

import { radius, spacing } from "../../../../../global/stylex/vars.stylex";
import type { ButtonProps } from "../Button";
import { Button } from "../Button";
import { IconButton } from "../IconButton";
import type { SidebarProps } from "../Sidebar";

const styles = stylex.create({
  active: {
    opacity: 1,
  },
  button: {
    backgroundColor: {
      ":focus-visible": null,
      ":hover": null,
      ":active": null,
    },
    gap: spacing.ms,
    justifyContent: "flex-start",
  },
  inactive: {
    opacity: {
      default: 0.5,
      ":focus-visible": 1,
      ":hover": 1,
      ":active": 1,
    },
  },
  iconButton: {
    backgroundColor: {
      ":focus-visible": null,
      ":hover": null,
      ":active": null,
    },
    borderRadius: radius.m,
  },
  link: {
    alignItems: "center",
    display: "flex",
  },
  nested: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.m,
    paddingLeft: spacing.xl,
  },
  span: {
    flex: 1,
  },
});

export type SidebarTabProps = LinkProps & {
  actionRight?: ReactNode;
  analyticsEvent?: AnalyticsEvent;
  children?: ReactNode;
  icon: ButtonProps["startIcon"];
  message: string;
  state?: SidebarProps["state"];
};

export const SidebarTab = ({
  actionRight,
  analyticsEvent,
  children,
  href,
  icon,
  message,
  state = "expanded",
  ...props
}: SidebarTabProps) => {
  const matcher = usePathMatch();
  const analytics = useAnalytics();
  const isSidebarTabActive = matcher(href.toString());

  if (state === "closed")
    return (
      <IconButton
        asChild
        icon={icon}
        styleXArray={[
          styles.iconButton,
          isSidebarTabActive ? styles.active : styles.inactive,
        ]}
        variant="transparentPrimary"
      >
        <Link href={href} {...props} />
      </IconButton>
    );

  return (
    <>
      <Button
        asChild
        isFullWidth
        startIcon={icon}
        styleXArray={[
          styles.button,
          isSidebarTabActive ? styles.active : styles.inactive,
        ]}
        variant="transparentPrimary"
      >
        <Link
          href={href}
          {...stylex.props(styles.link)}
          {...props}
          onClick={() => analyticsEvent && analytics.track(analyticsEvent)}
        >
          <span {...stylex.props(styles.span)}>
            <I18nMessageClient message={message} />
          </span>
          {actionRight}
        </Link>
      </Button>
      {children && <div {...stylex.props(styles.nested)}>{children}</div>}
    </>
  );
};

SidebarTab.displayName = "SidebarTab";
