"use client";

import { useTranslations } from "@packages/i18n";
import type { Collection, Images } from "@packages/sdk";
import { useRequestFavorites } from "@packages/sdk";
import stylex from "@stylexjs/stylex";
import type { MouseEvent } from "react";

import { spacing } from "../../../../../../global/stylex/vars.stylex";
import { useFavoriteCollectionAction } from "../../../../../hooks";
import type { WithStylexArray } from "../../../../../types";
import { AddToQueue } from "../../../../blocks";
import { IconButton } from "../../../_core";
import { MoreIcon, StarIcon } from "../../../icons";
import { DropdownMenu, DropdownMenuItem } from "../../../modals";
import ContentMoreMenuHeader from "./ContentMoreMenuHeader";

const styles = stylex.create({
  dropdownMenuContent: { paddingTop: spacing.l },
});

type CollectionMoreMenuProps = WithStylexArray<{
  collection: Pick<
    Collection,
    "id" | "title" | "sessions" | "desc" | "has_access"
  >;
  imageSrc?: Images;
}>;

export const CollectionMoreMenu = ({
  collection,
  imageSrc,
}: CollectionMoreMenuProps) => {
  const updateFavorite = useFavoriteCollectionAction({
    id: collection.id,
    has_access: collection.has_access ?? true,
  });

  const { query: favoritesQuery } = useRequestFavorites({
    displayType: "collections",
  });
  const t = useTranslations();

  const isFavorite = (favoritesQuery.data?.items ?? []).find(
    (favoriteCollection) => favoriteCollection.id === collection.id,
  );

  const onClickFavorite = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    updateFavorite();
  };

  return (
    <DropdownMenu
      trigger={
        <IconButton
          icon={<MoreIcon />}
          onClick={(e) => {
            // The stopImmediatePropagation call prevents next top loader from firing unnecessarily
            e.nativeEvent.stopImmediatePropagation();
            e.preventDefault();
          }}
          variant="transparentPrimary"
        />
      }
      align="end"
      contentStyleXArray={[styles.dropdownMenuContent]}
      modal={false}
    >
      <ContentMoreMenuHeader
        title={collection.title}
        subtitle={collection.desc}
        metadata={collection.sessions}
        imageSrc={imageSrc}
      />
      <DropdownMenuItem
        startIcon={isFavorite ? <StarIcon.Off /> : <StarIcon.Outline />}
        onClick={onClickFavorite}
      >
        {isFavorite
          ? t("dialog_prayer_options_unfavorite")
          : t("dialog_prayer_options_favorite")}
      </DropdownMenuItem>
      <AddToQueue type={"collection"} id={collection.id} />
    </DropdownMenu>
  );
};
