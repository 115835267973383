import type { Images } from "@packages/sdk/src/lib/types/schema/imagesSchema";
import * as stylex from "@stylexjs/stylex";

import {
  colors,
  dropShadow,
  numericPixels,
  numericValues,
  radius,
  shades,
  spacing,
} from "../../../../../../global/stylex/vars.stylex";
import { Thumbnail, VoxIcon } from "../../../../../components";
import type { HallowElementProps, WithStylexArray } from "../../../../../types";

const shimmerAnimation = stylex.keyframes({
  "0%": {
    opacity: 0,
    transform: "scale(1)",
  },
  "1%": {
    opacity: 0.5,
    transform: "scale(1)",
  },
  "40%": {
    opacity: 0,
    transform: "scale(1.3)",
  },
  "100%": {
    opacity: 0,
    transform: "scale(1)",
  },
});

const styles = stylex.create({
  image: {
    borderRadius: radius.m,
    boxShadow: `${numericValues[0]} ${dropShadow.ms} ${dropShadow.m} ${numericValues[0]} ${shades.shade20}`,
    opacity: 1,
    transition: "all 300ms ease-out",
    willChange: "width, height, border-radius, opacity",
  },
  shimmer: {
    animationDelay: "8s",
    animationDuration: "2s",
    animationIterationCount: "infinite",
    animationName: shimmerAnimation,
    borderRadius: radius.m,
    left: 0,
    opacity: 0,
    position: "absolute",
    willChange: "opacity, transform",
  },
  button: {
    "-webkit-tap-highlight-color": colors.transparent,
    background: colors.transparent,
    border: 0,
    cursor: {
      default: "pointer",
      ":disabled": "default",
    },
    lineHeight: numericValues[0],
    pointerEvents: "auto",
    position: "relative",
    transition: "opacity 300ms ease-out",
  },
  scrollStage1Image: {
    borderRadius: radius.s,
    height: numericPixels[40],
    width: numericPixels[40],
  },
  scrollStage2Button: {
    opacity: 0,
    position: "fixed",
    top: spacing.m,
  },
  icon: {
    display: "flex",
    height: numericPixels[40],
    left: "50%",
    placeContent: "center",
    placeItems: "center",
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: numericPixels[40],
    willChange: "width, height, opacity",
    zIndex: 1,
  },
  buttonIcon: {
    backgroundImage:
      'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxnIGZpbHRlcj0idXJsKCNmaWx0ZXIwX2JfMTQzM18xNjQ4KSI+DQo8cmVjdCB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHJ4PSIyMCIgZmlsbD0iYmxhY2siIGZpbGwtb3BhY2l0eT0iMC4zIi8+DQo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTE2Ljg5NDQgMTQuMjExMUMxNi4yNzQ1IDEzLjkwMTIgMTUuNTM4MiAxMy45MzQzIDE0Ljk0ODUgMTQuMjk4N0MxNC4zNTg5IDE0LjY2MzEgMTQgMTUuMzA2OCAxNCAxNlYyNEMxNCAyNC42OTMyIDE0LjM1ODkgMjUuMzM2OSAxNC45NDg1IDI1LjcwMTNDMTUuNTM4MiAyNi4wNjU3IDE2LjI3NDUgMjYuMDk4OCAxNi44OTQ0IDI1Ljc4ODlMMjQuODk0NCAyMS43ODg5QzI1LjU3MiAyMS40NTAxIDI2IDIwLjc1NzUgMjYgMjBDMjYgMTkuMjQyNSAyNS41NzIgMTguNTQ5OSAyNC44OTQ0IDE4LjIxMTFMMTYuODk0NCAxNC4yMTExWiIgZmlsbD0id2hpdGUiLz4NCjwvZz4NCjxkZWZzPg0KPGZpbHRlciBpZD0iZmlsdGVyMF9iXzE0MzNfMTY0OCIgeD0iLTQwIiB5PSItNDAiIHdpZHRoPSIxMjAiIGhlaWdodD0iMTIwIiBmaWx0ZXJVbml0cz0idXNlclNwYWNlT25Vc2UiIGNvbG9yLWludGVycG9sYXRpb24tZmlsdGVycz0ic1JHQiI+DQo8ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIwIiByZXN1bHQ9IkJhY2tncm91bmRJbWFnZUZpeCIvPg0KPGZlR2F1c3NpYW5CbHVyIGluPSJCYWNrZ3JvdW5kSW1hZ2VGaXgiIHN0ZERldmlhdGlvbj0iMjAiLz4NCjxmZUNvbXBvc2l0ZSBpbjI9IlNvdXJjZUFscGhhIiBvcGVyYXRvcj0iaW4iIHJlc3VsdD0iZWZmZWN0MV9iYWNrZ3JvdW5kQmx1cl8xNDMzXzE2NDgiLz4NCjxmZUJsZW5kIG1vZGU9Im5vcm1hbCIgaW49IlNvdXJjZUdyYXBoaWMiIGluMj0iZWZmZWN0MV9iYWNrZ3JvdW5kQmx1cl8xNDMzXzE2NDgiIHJlc3VsdD0ic2hhcGUiLz4NCjwvZmlsdGVyPg0KPC9kZWZzPg0KPC9zdmc+")',
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
  scrollStage1Icon: {
    height: numericPixels[20],
    width: numericPixels[20],
  },
  activatedContainer: {
    display: "flex",
    lineHeight: numericValues[0],
    placeContent: "center",
    placeItems: "center",
    position: "relative",
  },
});

export type ReaderThumbnailProps = WithStylexArray<
  HallowElementProps<"button"> & {
    activated: boolean;
    imageSrc: Images;
    playing: boolean;
    scrollStage: 0 | 1 | 2;
    title: string;
  }
>;

export const ReaderThumbnail = ({
  scrollStage,
  imageSrc,
  playing,
  activated,
  title,
  onClick,
  styleXArray,
}: ReaderThumbnailProps) => {
  const img = (
    <>
      {!activated && scrollStage < 1 ? (
        <Thumbnail
          size={"s"}
          imageSrc={imageSrc}
          title={title}
          styleXArray={[styles.shimmer]}
        />
      ) : null}
      <Thumbnail
        size={"s"}
        imageSrc={imageSrc}
        title={title}
        styleXArray={[
          styles.image,
          scrollStage > 0 ? styles.scrollStage1Image : null,
        ]}
      />
    </>
  );

  if (activated) {
    return (
      <div
        {...stylex.props(
          styles.activatedContainer,
          scrollStage > 1 ? styles.scrollStage2Button : null,
          styleXArray,
        )}
      >
        {img}
        <VoxIcon
          playing={playing}
          styleXArray={[
            styles.icon,
            scrollStage > 0 ? styles.scrollStage1Icon : null,
          ]}
        />
      </div>
    );
  }
  return (
    <button
      onClick={onClick}
      {...stylex.props(
        styles.button,
        scrollStage > 1 ? styles.scrollStage2Button : null,
        styleXArray,
      )}
    >
      {img}
      <span
        {...stylex.props(
          styles.icon,
          styles.buttonIcon,
          scrollStage > 0 ? styles.scrollStage1Icon : null,
        )}
      />
    </button>
  );
};
