"use client";

import type { MediaElementsProps, MediaElementsRef } from "@packages/media";
import { useCurrentQueueItem } from "@packages/media";
import * as stylex from "@stylexjs/stylex";
import { forwardRef } from "react";

import {
  numericPercentages,
  radius,
  spacing,
} from "../../../../../../global/stylex/vars.stylex";
import { useDynamicViewport } from "../../../../../hooks";
import { MediaElements } from "../MediaElements";

const styles = stylex.create({
  container: {
    aspectRatio: 1,
    borderRadius: radius.s,
    overflow: "hidden",
    position: "relative",
  },
  "collapsed-desktop": {
    width: spacing.xxxl,
  },
  "collapsed-mobile": {
    width: spacing.xxl,
  },
  "expanded-desktop": {
    height: numericPercentages[100],
  },
  "expanded-mobile": {
    aspectRatio: "unset",
    borderRadius: radius.none,
    height: "100%",
    minWidth: "100%",
  },
  "fullscreen-desktop": {
    aspectRatio: "unset",
    borderRadius: radius.none,
    height: "100vh",
    width: "100vw",
  },
  "fullscreen-mobile": {
    // this shouldn't be common because mobile devices take over when media is made full-screen
    // which means this will only be used on desktop devices where the screen size has been reduced to mobile-ish
    aspectRatio: "unset",
    borderRadius: radius.none,
    height: "100vh",
    width: "100vw",
  },
  hasVideo: {
    aspectRatio: "unset",
  },
  "hidden-desktop": {
    display: "none",
  },
  "hidden-mobile": {
    display: "none",
  },
});

export const MediaElementContainer = forwardRef<
  MediaElementsRef,
  MediaElementsProps
>(
  (
    { display, mediaType, mediaSrc, bgAudioSrc, bgVolume, shouldPlayBg },
    ref,
  ) => {
    const isDesktop = useDynamicViewport({ minimumWidth: 1024 });
    const size = `${display}-${!isDesktop ? "mobile" : "desktop"}`;
    const { currentItem } = useCurrentQueueItem();

    return (
      <div
        key={"mediaElementContainer"}
        {...stylex.props(
          styles.container,
          styles[size],
          mediaType === "video" ? styles.hasVideo : null,
        )}
      >
        <MediaElements
          key={"hallowMedia"}
          ref={ref}
          imgSrc={currentItem?.collection.images}
          mediaSrc={mediaSrc}
          bgAudioSrc={bgAudioSrc}
          mediaType={mediaType}
          mediaTitle={currentItem?.prayer.title}
          bgVolume={bgVolume}
          shouldPlayBg={shouldPlayBg}
        />
      </div>
    );
  },
);

MediaElementContainer.displayName = "MediaElementContainer";
