"use client";

import { HallowAnalyticsEvent, useAnalytics } from "@packages/analytics";
import { I18nMessageClient, useTranslations } from "@packages/i18n";
import { useRequestCommunitiesAdminCommunities } from "@packages/sdk";
import * as Dialog from "@radix-ui/react-dialog";
import * as stylex from "@stylexjs/stylex";
import Link from "next/link";
import { useParams } from "next/navigation";

import {
  colors,
  numericPercentages,
  radius,
  semanticColors,
  spacing,
  stroke,
  zIndices,
} from "../../../../../../global/stylex/vars.stylex";
import { useHover } from "../../../../../reactAria";
import type { CSSColorStrict } from "../../../../../utils";
import {
  AvatarCommunity,
  CheckmarkIcon,
  ChevronDownIcon,
  CloseIcon,
  IconButton,
  ListProfile,
  Skeleton,
  Text,
} from "../../../../_base";

const styles = stylex.create({
  checkmark: {
    color: semanticColors.neutralsMedium,
  },
  communities: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.xxs,
    paddingBottom: spacing.m,
    paddingLeft: spacing.m,
    paddingRight: spacing.m,
  },
  communitySelected: {
    backgroundColor: semanticColors.neutralsLowest,
  },
  content: {
    backgroundColor: semanticColors.onOverlayBackground,
    borderRadius: radius.l,
    display: "flex",
    flexDirection: "column",
    left: numericPercentages[50],
    margin: spacing.m,
    maxHeight: 400,
    maxWidth: 400,
    overflowY: "auto",
    position: "fixed",
    top: numericPercentages[50],
    transform: "translate(-50%, -50%)",
    width: spacing.full,
    zIndex: zIndices.modalContent,
  },
  header: {
    alignItems: "center",
    display: "flex",
    gap: spacing.s,
    padding: spacing.m,
    position: "sticky",
    top: 0,
  },
  overlay: {
    backgroundColor: semanticColors.higherContrastMed,
    inset: spacing.none,
    position: "fixed",
    zIndex: zIndices.overlayOverMediaPlayer,
  },
  skeleton: {
    margin: "0 auto",
  },
  skeletonTriggerClosed: {
    height: 24,
    width: 24,
  },
  skeletonTriggerExpanded: {
    height: 40,
  },
  title: {
    flex: 1,
    textAlign: "center",
  },
  trigger: {
    background: {
      default: colors.transparent,
      ":hover": semanticColors.neutralsLowest,
    },
    borderRadius: radius.ms,
  },
  triggerExpanded: {
    alignItems: "center",
    borderColor: semanticColors.neutralsLowest,
    borderStyle: "solid",
    borderWidth: stroke.regular,
    cursor: "pointer",
    display: "flex",
    gap: spacing.ms,
    height: 40,
    outline: "none",
    padding: spacing.s,
    transition: "background-color 150ms ease-in",
    width: spacing.full,
  },
  triggerClosedContainer: {
    alignItems: "center",
    display: "flex",
    height: 40,
    justifyContent: "center",
    width: 40,
  },
  triggerIcon: {
    color: semanticColors.primary,
  },
  triggerText: {
    color: semanticColors.primary,
    flex: 1,
    opacity: 0.5,
    textAlign: "left",
    transition: "color 150ms ease-in",
  },
  triggerTextHover: {
    opacity: 1,
  },
  communitySelectorClosed: {
    cursor: "pointer",
    marginHorizontal: "auto",
  },
});

export type CommunitySelectorProps = Dialog.DialogProps & {
  triggerState?: "expanded" | "closed";
};

export const CommunitySelector = ({
  triggerState = "expanded",
  ...props
}: CommunitySelectorProps) => {
  const analytics = useAnalytics();
  const { hoverProps, isHovered } = useHover({});
  const { communityId } = useParams();
  const { query: queryCommunitiesAdminCommunities } =
    useRequestCommunitiesAdminCommunities();
  const t = useTranslations();

  if (!queryCommunitiesAdminCommunities.data)
    return (
      <Skeleton
        styleXArray={[
          styles.skeleton,
          triggerState === "closed"
            ? styles.skeletonTriggerClosed
            : styles.skeletonTriggerExpanded,
        ]}
      />
    );

  const communitySelected = queryCommunitiesAdminCommunities.data.results.find(
    (community) => {
      return community.id === parseInt(communityId as string);
    },
  );

  const handleCommunityOnClick = () =>
    analytics.track({
      event: HallowAnalyticsEvent.TappedWebCommunity,
    });

  const handleTriggerOnClick = () =>
    analytics.track({
      event: HallowAnalyticsEvent.TappedWebCommunityDropdown,
    });

  return (
    <Dialog.Root {...props}>
      <Dialog.Trigger
        {...hoverProps}
        {...stylex.props(
          styles.trigger,
          triggerState === "expanded" && styles.triggerExpanded,
        )}
        asChild={triggerState === "closed"}
        onClick={handleTriggerOnClick}
      >
        {triggerState === "expanded" ? (
          <>
            <AvatarCommunity
              color={communitySelected?.color_hex as CSSColorStrict}
              imgSrc={communitySelected?.image_url}
              loading={!communitySelected}
              name={communitySelected?.name}
              size="xs"
            />
            <Text
              overflow="ellipsis"
              size="m"
              type="button"
              styleXArray={[
                styles.triggerText,
                isHovered ? styles.triggerTextHover : null,
              ]}
            >
              {communitySelected?.name}
            </Text>
            <ChevronDownIcon
              opacity={0.5}
              {...stylex.props(styles.triggerIcon)}
            />
          </>
        ) : (
          <div {...stylex.props(styles.triggerClosedContainer)}>
            <AvatarCommunity
              color={communitySelected?.color_hex as CSSColorStrict}
              imgSrc={communitySelected?.image_url}
              loading={!communitySelected}
              name={communitySelected?.name}
              size="xs"
              styleXArray={[styles.communitySelectorClosed]}
            />
          </div>
        )}
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay {...stylex.props(styles.overlay)} />
        <Dialog.Content {...stylex.props(styles.content)}>
          <div {...stylex.props(styles.header)}>
            <Dialog.Title asChild>
              <Text size="m" styleXArray={[styles.title]} type="title">
                <I18nMessageClient message="web_select_your_group" />
              </Text>
            </Dialog.Title>
            <Dialog.Close asChild>
              <IconButton
                icon={<CloseIcon />}
                size="s"
                variant="transparentPrimary"
              />
            </Dialog.Close>
          </div>
          <div {...stylex.props(styles.communities)}>
            {queryCommunitiesAdminCommunities.data?.results.map((community) => (
              <ListProfile
                asChild
                avatar={
                  <AvatarCommunity
                    color={community?.color_hex as CSSColorStrict}
                    imgSrc={community?.image_url}
                    key="avatar"
                    name={community?.name}
                    size="m"
                  />
                }
                description={community?.simple_address_label}
                icon={
                  community?.id === parseInt(communityId as string) ? (
                    <CheckmarkIcon.SimpleOn
                      {...stylex.props(styles.checkmark)}
                    />
                  ) : null
                }
                key={community.id}
                metadata={t("create_community_content_member_count", {
                  create_community_content_member_count:
                    community.member_summary.member_count,
                })}
                onClick={handleCommunityOnClick}
                styleXArray={[
                  community?.id === parseInt(communityId as string)
                    ? styles.communitySelected
                    : null,
                ]}
                title={community?.name}
              >
                <Link href={`/admin/${community.id}/dashboard`} />
              </ListProfile>
            ))}
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
