"use client";

import * as stylex from "@stylexjs/stylex";
import { forwardRef, useState } from "react";
import { useFocus, useHover } from "react-aria";

import {
  colors,
  semanticColors,
  semanticColorsRaw,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import type { IconButtonProps } from "../IconButton";
import { IconButton } from "../IconButton";
import { Text } from "../Text";

const styles = stylex.create({
  base: {
    "-webkit-tap-highlight-color": "transparent",
    alignItems: "center",
    background: colors.transparent,
    border: "none",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    gap: spacing.xxs,
    padding: spacing.none,
    transition: "all 100ms",
    width: spacing.xxxl,
  },
});

const stylesDisabled = stylex.create({
  base: {
    cursor: "not-allowed",
    opacity: 0.5,
  },
});

const stylesVariant = stylex.create({
  neutral: {
    color: semanticColors.neutralsVeryHigh,
  },
  transparentPrimary: {
    color: semanticColors.neutralsVeryHigh,
  },
  transparentWhite: {
    color: semanticColors.onColorVeryHigh,
  },
});

const stylesVariantIsFocused = stylex.create({
  neutral: {
    color: semanticColors.neutralsMedium,
  },
  transparentPrimary: {
    color: semanticColors.neutralsMedium,
  },
  transparentWhite: {
    color: semanticColors.onColorVeryHigh,
  },
});

const stylesVariantIsHovered = stylex.create({
  neutral: {
    color: semanticColors.neutralsVeryHigh,
  },
  transparentPrimary: {
    color: semanticColors.neutralsVeryHigh,
  },
  transparentWhite: {
    color: semanticColors.onColorHighest,
  },
});

const stylesVariantIconButtonIsFocused = stylex.create({
  neutral: {
    backgroundColor: semanticColors.neutralsVeryLow,
    color: semanticColors.neutralsMedium,
  },
  transparentPrimary: {
    backgroundColor: semanticColors.neutralsLowest,
    color: semanticColors.neutralsMedLow,
  },
  transparentWhite: {
    backgroundColor: semanticColors.fixedLightenMedLow,
    color: semanticColors.onColorVeryHigh,
  },
});

const stylesVariantIconButtonIsHovered = stylex.create({
  neutral: {
    backgroundColor: semanticColors.neutralsVeryLow,
    color: semanticColors.neutralsVeryHigh,
  },
  transparentPrimary: {
    backgroundColor: semanticColors.neutralsLowest,
    color: semanticColors.neutralsVeryHigh,
  },
  transparentWhite: {
    backgroundColor: semanticColors.fixedLightenMedLow,
    color: semanticColors.onColorHighest,
  },
});

export type QuickActionButtonProps = IconButtonProps & {
  variant?: "neutral" | "transparentPrimary" | "transparentWhite";
  size?: "m" | "l";
};

export const QuickAction = forwardRef<any, QuickActionButtonProps>(
  (
    {
      children,
      disabled = false,
      variant = "transparentPrimary",
      size = "m",
      icon,
      color = semanticColorsRaw.accentMedium.default,
      ...props
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const { focusProps } = useFocus({
      onFocusChange: (isFocused) => setIsFocused(isFocused),
    });
    const { hoverProps, isHovered } = useHover({});

    return (
      <Text asChild size={size === "l" ? "m" : "xs"} type="title">
        <button
          disabled={disabled}
          ref={ref}
          {...focusProps}
          {...hoverProps}
          {...props}
          {...stylex.props(
            styles.base,
            stylesVariant[variant],
            disabled ? stylesDisabled.base : null,
            !disabled && isFocused ? stylesVariantIsFocused[variant] : null,
            !disabled && isHovered ? stylesVariantIsHovered[variant] : null,
          )}
        >
          <IconButton
            asChild
            icon={icon}
            variant={variant}
            size={size}
            styleXArray={[
              !disabled && isFocused
                ? stylesVariantIconButtonIsFocused[variant]
                : null,
              !disabled && isHovered
                ? stylesVariantIconButtonIsHovered[variant]
                : null,
            ]}
          >
            <span />
          </IconButton>
          {children}
        </button>
      </Text>
    );
  },
);

QuickAction.displayName = "QuickAction";
