"use client";

import * as RadixUiDropdownMenu from "@radix-ui/react-dropdown-menu";
import * as stylex from "@stylexjs/stylex";
import React, { forwardRef, Fragment, useCallback, useState } from "react";
import type { Control } from "react-hook-form";
import { useController } from "react-hook-form";
import * as RPNInput from "react-phone-number-input";

import {
  dropShadow,
  numericPercentages,
  numericPixels,
  numericValues,
  radius,
  semanticColors,
  shades,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import { useTheme } from "../../../../theme";
import type { WithStylexArray } from "../../../../types";
import { CheckmarkIcon, ChevronDownIcon } from "../../../_base";
import { ScrollArea } from "../../other";
import { TextInput } from "../TextInput";

const styles = stylex.create({
  base: {
    display: "flex",
    justifyItems: "stretch",
    width: numericPercentages[100],
  },
  container: {},
  input: {
    borderBottomLeftRadius: "0px",
    borderLeftColor: "transparent",
    borderTopLeftRadius: "0px",
    flexGrow: 1,
    ":hover": {
      borderLeftColor: semanticColors.neutralsVeryLow,
    },
    ":focus": {
      borderLeftColor: semanticColors.primary,
    },
  },
  button: {
    alignItems: "center",
    backgroundColor: semanticColors.neutralsLowest,
    border: `2px solid ${semanticColors.neutralsLowest}`,
    borderRadius: `${radius.ms} 0 0 ${radius.ms}`,
    borderRight: "none",
    cursor: "pointer",
    display: "flex",
    height: "auto",
    justifyContent: "center",
    paddingLeft: spacing.ms,
    position: "relative",
    width: `${numericPixels[72]}`,
    ":hover": {
      backgroundColor: semanticColors.neutralsVeryLow,
      borderColor: semanticColors.neutralsVeryLow,
      zIndex: "1",
    },
  },
  dropdownContent: {
    backgroundColor: semanticColors.onOverlayBackground,
    border: `1px solid ${semanticColors.neutralsLowest}`,
    borderRadius: radius.ms,
    boxShadow: `${numericValues[0]} ${dropShadow.s} ${dropShadow.ms} ${numericValues[0]} ${shades.shade10}`,
    marginTop: spacing.xxs,
  },
  scroll: {
    height: numericPixels[200],
  },
  dropdownContentInner: {
    padding: spacing.xxs,
  },
  dropdownItem: {
    alignItems: "center",
    borderRadius: radius.s,
    cursor: "pointer",
    display: "flex",
    gap: spacing.s,
    padding: spacing.s,
    ":hover": {
      backgroundColor: semanticColors.neutralsLowest,
    },
  },
  dropdownItemSelected: {
    backgroundColor: semanticColors.neutralsLowest,
    ":hover": {
      backgroundColor: semanticColors.neutralsVeryLow,
    },
  },
  dropdownItemTitle: {
    flexGrow: 1,
  },
  dropdownItemCode: {
    color: semanticColors.neutralsMedium,
    padding: `0 ${spacing.xs}`,
  },
  flag: {
    borderRadius: radius.xs,
    height: numericPixels[16],
    overflow: "hidden",
    width: numericPixels[24],
  },
});

type PhoneInputProps = WithStylexArray<
  Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange" | "value">
> & {
  control: Control<any, any>;
  locale?: string;
  required?: boolean;
} & Omit<RPNInput.Props<typeof RPNInput.default>, "onChange"> & {
    onChange?: (value: RPNInput.Value) => void;
  };

export const PhoneInput = ({
  name,
  control,
  locale,
  required = false,
  onChange,
  ...props
}: PhoneInputProps) => {
  const [country, setCountry] = useState<RPNInput.Country>("US");
  const {
    field: { ref, ...field },
  } = useController({
    name,
    control,
    rules: { required },
  });

  return (
    <RPNInput.default
      ref={ref}
      {...field}
      {...stylex.props(styles.base)}
      value={field.value}
      international
      defaultCountry={country}
      flagComponent={FlagComponent}
      countrySelectComponent={CountrySelect}
      inputComponent={InputComponent}
      onChange={(value) => field.onChange?.(value || "")}
      onCountryChange={(country) => setCountry(country)}
      {...props}
    />
  );
};

PhoneInput.displayName = "PhoneInput";

const InputComponent = forwardRef<HTMLInputElement>((props, ref) => {
  return <TextInput {...props} styleXArray={[styles.input]} ref={ref} />;
});

type CountrySelectOption = { label: string; value: RPNInput.Country };

type CountrySelectProps = {
  disabled?: boolean;
  value: RPNInput.Country;
  onChange: (value: RPNInput.Country) => void;
  options: CountrySelectOption[];
};

const CountrySelect = ({
  disabled,
  value,
  onChange,
  options,
}: CountrySelectProps) => {
  const { themeContainerRef } = useTheme();

  // Setup lists of countries to display
  const preferredCodes = ["US", "MX", "BR", "PH", "PL"];
  const preferredCountries = preferredCodes
    .map((code) => options.find((x) => x.value === code))
    .filter(Boolean);
  const otherCountries = options.filter(
    (x) => !preferredCodes.includes(x.value),
  );

  // Callbacks
  const handleSelect = useCallback(
    (country: RPNInput.Country) => {
      onChange(country);
    },
    [onChange],
  );

  return (
    <RadixUiDropdownMenu.Root>
      <RadixUiDropdownMenu.Trigger asChild>
        <button disabled={disabled} {...stylex.props(styles.button)}>
          <FlagComponent country={value} countryName={value} />
          <ChevronDownIcon />
        </button>
      </RadixUiDropdownMenu.Trigger>
      <RadixUiDropdownMenu.Portal container={themeContainerRef.current}>
        <Fragment>
          <RadixUiDropdownMenu.Content
            align="start"
            {...stylex.props(styles.dropdownContent)}
          >
            <ScrollArea styleXArray={[styles.scroll]}>
              <div {...stylex.props(styles.dropdownContentInner)}>
                {[...preferredCountries, ...otherCountries]
                  .filter((x) => x.value)
                  .map((option) => (
                    <RadixUiDropdownMenu.Item
                      {...stylex.props(
                        styles.dropdownItem,
                        option.value === value && styles.dropdownItemSelected,
                      )}
                      key={option.value}
                      onClick={() => handleSelect(option.value)}
                    >
                      <FlagComponent
                        country={option.value}
                        countryName={option.label}
                      />
                      <div {...stylex.props(styles.dropdownItemTitle)}>
                        <span>{option.label}</span>
                        {option.value && (
                          <span {...stylex.props(styles.dropdownItemCode)}>
                            {`+${RPNInput.getCountryCallingCode(option.value)}`}
                          </span>
                        )}
                      </div>
                      {option.value === value && <CheckmarkIcon.SimpleOn />}
                    </RadixUiDropdownMenu.Item>
                  ))}
              </div>
            </ScrollArea>
          </RadixUiDropdownMenu.Content>
        </Fragment>
      </RadixUiDropdownMenu.Portal>
    </RadixUiDropdownMenu.Root>
  );
};
CountrySelect.displayName = "CountrySelect";

const FlagComponent = ({ country }: RPNInput.FlagProps) => {
  const getUnicodeCountryFlag = (country) => {
    if (!country) return null;
    return (
      getRegionalIndicatorSymbol(country[0]) +
      getRegionalIndicatorSymbol(country[1])
    );
  };

  // Magic strings to get the regional indicator symbol
  // https://gitlab.com/catamphetamine/country-flag-icons/-/blob/master/source/unicode.js?ref_type=heads
  const getRegionalIndicatorSymbol = (letter) => {
    return String.fromCodePoint(
      0x1f1e6 - 65 + letter.toUpperCase().charCodeAt(0),
    );
  };

  return (
    <span {...stylex.props(styles.flag)}>{getUnicodeCountryFlag(country)}</span>
  );
};
FlagComponent.displayName = "FlagComponent";
