"use client";

import { type Images } from "@packages/sdk";
import * as stylex from "@stylexjs/stylex";
import { forwardRef } from "react";

import {
  stylesOpacity,
  stylesOutline,
  stylesScale,
} from "../../../../global/stylex/styles";
import {
  radius,
  semanticColors,
  spacing,
  spacingMasks,
  zIndices,
} from "../../../../global/stylex/vars.stylex";
import { useStylex } from "../../../hooks";
import type {
  HallowElement,
  HallowElementProps,
  KeyedElement,
  StyleXArray,
  WithAsChild,
  WithStylexArray,
} from "../../../types";
import {
  determineChildrenInject,
  determineElementFromAsChild,
} from "../../../utils";
import type { MaskProps, TextProps } from "../../_base";
import { SubscriptionIcon } from "../../_base";
import { Mask, Tag, Text } from "../../_base";
import { HallowImage } from "../../blocks";

const hallowElement: HallowElement = "div";

const styles = stylex.create({
  centerActions: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    left: "50%",
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  container: (props: { color: MaskProps["color"] }) => ({
    "-webkit-backface-visibility": "hidden",
    backgroundColor: props.color,
    borderRadius: spacing.m,
    display: "flex",
    flexDirection: "column",
    height: spacing.full,
    position: "absolute",
    top: spacing.none,
    width: spacing.full,
  }),
  description: {
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    color: semanticColors.onColorHighest,
    display: "-webkit-box",
    overflow: "hidden",
  },
  determinedElement: {
    borderRadius: radius.m,
    display: "block",
    paddingBottom: "60px",
    paddingTop: spacing.full,
    position: "relative",
    width: spacing.full,
  },
  img: {
    "-webkit-user-drag": "none",
    borderTopLeftRadius: radius.m,
    borderTopRightRadius: radius.m,
    height: spacing.full,
    objectFit: "cover",
    objectPosition: "center",
    objectRepeat: "no-repeat",
    userSelect: "none",
    width: spacing.full,
  },
  imgBlur: {
    filter: `blur(${spacing.ml})`,
    transform: "scale(1.1)",
  },
  imgContainer: {
    borderTopLeftRadius: radius.m,
    borderTopRightRadius: radius.m,
    flexGrow: 1,
    position: "relative",
  },
  imgContainerBlur: {
    overflow: "hidden",
  },
  infoContainer: {
    alignItems: "end",
    borderBottomLeftRadius: radius.m,
    borderBottomRightRadius: radius.m,
    display: "flex",
    gap: spacing.s,
    marginTop: "-1px",
    paddingBottom: spacing.l,
    paddingLeft: spacing.l,
    paddingRight: spacing.l,
    paddingTop: spacing.xxs,
  },
  infoContainerLeftContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    gap: spacing.s,
    height: spacing.full,
    minWidth: 0,
  },
  maskContainer: {
    bottom: spacing.none,
    display: "flex",
    flexDirection: "column",
    gap: spacing.s,
    height: spacingMasks.m,
    justifyContent: "flex-end",
    paddingBottom: spacing.none,
    paddingLeft: spacing.m,
    paddingRight: spacing.m,
    position: "absolute",
    width: spacing.full,
    zIndex: zIndices.absolute,
  },
  mask: {
    bottom: spacing.none,
    left: spacing.none,
    position: "absolute",
    right: spacing.none,
    top: spacing.none,
  },
  rightActions: {
    alignItems: "center",
    display: "flex",
    gap: spacing.xxs,
  },
  tag: {
    zIndex: 0,
  },
  tagsContainer: {
    alignItems: "center",
    display: "flex",
    gap: spacing.xxs,
  },
  title: {
    flex: 1,
    overflow: "visible",
    position: "relative",
  },
  titleContainer: {
    alignItems: "center",
    color: semanticColors.onColorHighest,
    display: "flex",
    gap: spacing.xxs,
    zIndex: zIndices.absolute,
  },
});

export type CardProps = WithAsChild<
  WithStylexArray<HallowElementProps<typeof hallowElement>>
> & {
  activeState?: boolean;
  alternativeTitle?: string;
  centerActions?: KeyedElement[];
  color?: MaskProps["color"];
  description?: string;
  descriptionProps?: TextProps;
  focusState?: boolean;
  hoverState?: boolean;
  imgBlur?: boolean;
  imgContainerProps?: HallowElementProps<"div">;
  imgContainerStyleXArray?: StyleXArray;
  imgSrc: Images;
  imgStyleXArray?: StyleXArray;
  infoContainerStyleXArray?: StyleXArray;
  maskProps?: MaskProps;
  rightActions?: KeyedElement[];
  subscription?: boolean;
  tag?: string;
  tags?: string[];
  title?: string;
  titleProps?: TextProps;
};

export const Card = forwardRef<any, CardProps>(
  (
    {
      activeState = true,
      alternativeTitle,
      asChild = false,
      centerActions,
      children,
      color = "black",
      description,
      descriptionProps,
      focusState = true,
      hoverState = true,
      imgBlur,
      imgContainerProps,
      imgContainerStyleXArray,
      imgSrc,
      imgStyleXArray,
      infoContainerStyleXArray,
      maskProps,
      rightActions,
      styleXArray,
      subscription,
      tag,
      tags,
      title,
      titleProps,
      ...props
    },
    ref,
  ) => {
    const { getStylexColorVarValue } = useStylex();

    const DeterminedElement = determineElementFromAsChild({
      asChild,
      hallowElement,
    });

    const DeterminedChildren = determineChildrenInject({
      afterChildrenInject: [
        <div
          key="container"
          {...stylex.props(
            styles.container({ color: getStylexColorVarValue({ color }) }),
          )}
        >
          <div
            {...imgContainerProps}
            {...stylex.props(
              styles.imgContainer,
              imgBlur ? styles.imgContainerBlur : null,
              imgContainerStyleXArray,
            )}
          >
            {imgContainerProps?.children}
            {imgSrc && (
              <HallowImage
                alt={title}
                src={imgSrc}
                size={"m"}
                {...stylex.props(
                  styles.img,
                  imgBlur ? styles.imgBlur : null,
                  imgStyleXArray,
                )}
              />
            )}
            <div {...stylex.props(styles.maskContainer)}>
              <Mask
                color={color}
                direction="to top"
                size="m"
                {...maskProps}
                styleXArray={[styles.mask, maskProps?.styleXArray]}
              />
              {tag && (
                <Tag styleXArray={[styles.tag]} variant="lighten">
                  {tag}
                </Tag>
              )}
              {title && (
                <div {...stylex.props(styles.titleContainer)}>
                  {subscription && <SubscriptionIcon.Filled />}
                  <Text
                    {...({
                      overflow: "ellipsis",
                      size: "l",
                      type: "title",
                      as: "h3",
                      ...titleProps,
                      styleXArray: [styles.title, titleProps?.styleXArray],
                    } as TextProps)}
                    data-content={title}
                  >
                    {title}
                  </Text>
                </div>
              )}
            </div>
            {centerActions?.length > 0 && (
              <div {...stylex.props(styles.centerActions)}>{centerActions}</div>
            )}
          </div>
          <div
            {...stylex.props(styles.infoContainer, infoContainerStyleXArray)}
          >
            <div {...stylex.props(styles.infoContainerLeftContainer)}>
              {alternativeTitle && (
                <div {...stylex.props(styles.titleContainer)}>
                  {subscription && <SubscriptionIcon.Filled />}
                  <Text
                    {...({
                      overflow: "ellipsis",
                      size: "l",
                      type: "title",
                      as: "h3",
                      ...titleProps,
                      styleXArray: [styles.title, titleProps?.styleXArray],
                    } as TextProps)}
                    data-content={title}
                  >
                    {alternativeTitle}
                  </Text>
                </div>
              )}
              {description && (
                <Text
                  {...({
                    size: "s",
                    type: "body",
                    ...descriptionProps,
                    styleXArray: [
                      styles.description,
                      descriptionProps?.styleXArray,
                    ],
                  } as TextProps)}
                >
                  {description}
                </Text>
              )}
              {tags?.length > 0 && (
                <div {...stylex.props(styles.tagsContainer)}>
                  {tags?.map((tag, index) => (
                    <Tag key={`${tag}${index}`} variant="lighten">
                      {tag}
                    </Tag>
                  ))}
                </div>
              )}
            </div>
            {rightActions?.length > 0 && (
              <div {...stylex.props(styles.rightActions)}>{rightActions}</div>
            )}
          </div>
        </div>,
      ],
      children: children as JSX.Element,
    });

    return (
      <DeterminedElement
        ref={ref}
        {...props}
        {...stylex.props(
          styles.determinedElement,
          activeState ? stylesOpacity.active : null,
          activeState ? stylesOpacity.base : null,
          stylesOutline.base,
          focusState ? stylesOutline.focus : null,
          hoverState ? stylesOutline.hover : null,
          activeState && hoverState ? stylesScale.base : null,
          activeState && hoverState ? stylesScale.m : null,
          styleXArray,
          styleXArray,
        )}
      >
        {DeterminedChildren}
      </DeterminedElement>
    );
  },
);

Card.displayName = "Card";
