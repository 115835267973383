"use client";

import type { AnalyticsHook } from "@packages/analytics";
import { useAnalytics } from "@packages/analytics";
import { useTranslations } from "@packages/i18n";
import type { ReferenceType } from "@packages/sdk";
import { useRequestMe } from "@packages/sdk";
import {
  getDeeplink,
  type Section as SectionSchemaType,
  splitArray,
} from "@packages/sdk";
import { mockDataTrivia } from "@packages/sdk/global/mockData";
import { BranchContext } from "@packages/sdk/src/lib/providers/BranchProvider";
import * as stylex from "@stylexjs/stylex";
import Link from "next/link";
import type { MouseEventHandler } from "react";
import { useContext } from "react";
import { forwardRef } from "react";

import { spacing } from "../../../../../../global/stylex/vars.stylex";
import type { CarouselApi } from "../../../../../hooks";
import { useModal } from "../../../../../niceModal";
import type {
  HallowElement,
  HallowElementProps,
  KeyedElement,
  WithAsChild,
  WithStylexArray,
} from "../../../../../types";
import type { GridProps } from "../../../../blocks";
import { Container, Grid, GridItem, Stack } from "../../../../blocks/layout";
import { ChallengeCTAButton } from "../../../../blocks/primitives";
import { Button, IconButton, Text } from "../../../_core";
import { Collection2Icon, RadioIcon, ShareIcon } from "../../../icons";
import { ShareModal } from "../../../modals";
import type { CampaignMediumProps } from "../../campaigns";
import {
  type CampaignLargeProps,
  type CampaignLargeWithAuthorProps,
  CampaignMedium,
} from "../../campaigns";
import { CampaignLargeWithAuthor } from "../../campaigns";
import type { CategoryMediumProps } from "../../categories";
import { CategoryLarge, CategoryMedium } from "../../categories";
import type { ChallengeLargeProps } from "../../challenges";
import { ChallengeLarge } from "../../challenges";
import type {
  ContentLargeProps,
  ContentMediumProps,
  ContentSmallProps,
} from "../../content";
import {
  CollectionMoreMenu,
  ContentLarge,
  ContentMedium,
  ContentSmall,
  PrayerMoreMenu,
} from "../../content";
import type { DailyQuoteProps } from "../../other";
import { DailyQuote } from "../../other";
import type { TriviaLargeProps } from "../../trivia";
import { TriviaLarge } from "../../trivia";
import { SectionCarousel } from "./SectionCarousel";

type BaseGetSectionItemProps = Pick<
  SectionProps,
  "itemTypeLinkSearchParams" | "link" | "mutate"
> & {
  analytics?: AnalyticsHook;
  item: any;
  t: Function;
};

/**
 * Functions must be defined in this file, Babel/Webpack error(?), move with caution
 * TODO: abstract into /utils
 * TODO: solve Babel/Webpack error(?)
 */

/** CAMPAIGN */

export type GetSectionItemCampaignPropsProps = BaseGetSectionItemProps & {
  section?: SectionSchemaType;
};

export const getSectionItemCampaignProps = ({
  analytics,
  item,
  link,
  section,
  t,
}: GetSectionItemCampaignPropsProps): CampaignLargeWithAuthorProps => ({
  authorProps: {
    avatarProps: {
      imgSrc: item?.reference?.organizer?.image_url,
    },
    name: item?.reference?.organizer?.name,
  },
  campaignId: item?.reference.id,
  campaignName: item?.reference.name,
  campaignLargeProps: {
    actions: link ? (
      <Button
        asChild
        key="links"
        onPointerDown={() => {
          analytics?.track({
            event: "Tapped Section Item",
            properties: {
              // TODO: notes say page_id = -1 for campaign_list 'Viewed Screen' event, but requestPagesTrusted returns 83
              // does home have a different page_id?
              // Refactor: This event should be logged for every Section Item (move event up DOM tree)
              page_id: -1,
              section_id: section?.id,
              section_item_id: item?.item_id ?? null,
              section_title: section?.title ?? null,
              section_type: section?.type ?? null,
              index: section?.items.indexOf(item) ?? null,
              item_type: item?.type ?? null,
            },
          });
        }}
        variant="lighten"
      >
        <Link href={`/campaigns/${item?.reference.id}`}>
          {t("web_details")}
        </Link>
      </Button>
    ) : null,
    campaignLargeMobileProps: {
      asChild: link,
      children: <Link href={`/campaigns/${item?.reference.id}`} />,
    },
  },
  color: item?.reference?.content_image
    ?.color_hex as CampaignLargeProps["color"],
  description: item?.reference?.name,
  imgSrc: item?.reference?.image,
  illustrationImgSrc: item?.reference?.content_image?.large,
  item: item,
  joined: item?.reference?.has_joined,
  hasEnded: new Date() > new Date(item?.reference?.ends_at),
  section: section,
  tags: item?.reference?.labels,
  title: item?.reference?.content_title,
});

export type GetSectionItemCampaignMediumPropsProps = BaseGetSectionItemProps;

export const getSectionItemCampaignMediumProps = ({
  item,
  link,
}: GetSectionItemCampaignMediumPropsProps): CampaignMediumProps => ({
  asChild: link,
  children: link ? <Link href={`/campaigns/${item?.reference?.id}`} /> : null,
  color: item?.reference?.content_image
    ?.color_hex as CampaignLargeProps["color"],
  description: item?.reference?.name,
  imgSrc: item?.reference?.image,
  illustrationImgSrc: item?.reference?.content_image?.large,
  metadata: item?.reference?.labels[0],
  title: item?.reference?.content_title,
});

/** ITEM */

export type GetSectionItemCategoryPropsProps = BaseGetSectionItemProps & {
  size?: "m" | "l";
};

export const getSectionItemCategoryProps = ({
  item,
  link,
  size,
}: GetSectionItemCategoryPropsProps): CategoryMediumProps => ({
  asChild: link,
  children: link ? (
    <Link href={getDeeplink({ string: item?.reference?.deeplink })} />
  ) : null,
  color: item?.reference?.color_hex as CategoryMediumProps["color"],
  description: item?.reference?.description,
  imgSrc: { large: item?.reference?.image_url },
  noDescription: size === "m",
  title: item?.reference?.title,
});

/**
 * TODO: JoinButton
 * TODO: MediaPlayer
 */
export type GetSectionItemChallengePropsProps = BaseGetSectionItemProps;

export const getSectionItemChallengeProps = ({
  item,
  link,
  mutate,
  t,
}: GetSectionItemChallengePropsProps): ChallengeLargeProps => {
  const referenceData =
    item?.reference?.challenge ?? item.reference?.collection ?? item?.reference;
  const referenceCollectionData =
    item.reference?.collection ?? referenceData?.collection ?? referenceData;

  const actions = [
    mutate ? (
      <ChallengeCTAButton collection={referenceCollectionData} key="cta" />
    ) : null,
    link ? (
      <Button asChild key="link" variant="lighten">
        <Link href={`/collections/${referenceCollectionData.id}`}>
          {t("web_details")}
        </Link>
      </Button>
    ) : null,
  ];

  const centerActions = [
    mutate ? (
      <ChallengeCTAButton collection={referenceCollectionData} key="cta" />
    ) : null,
  ];

  return {
    actions,
    centerActions,
    asChild: link,
    availableAt:
      referenceData?.available_at ?? referenceCollectionData?.available_at,
    children: link ? (
      <Link href={`/collections/${referenceCollectionData.id}`} />
    ) : null,
    color: referenceCollectionData?.images
      ?.color_hex as ChallengeLargeProps["color"],
    description:
      referenceCollectionData?.short_desc ?? referenceCollectionData?.desc,
    endsAt: referenceData?.ends_at ?? referenceCollectionData?.ends_at,
    imgBlur: !(
      referenceData?.has_joined || referenceCollectionData?.has_joined
    ),
    imgSrc: referenceCollectionData?.images,
    subscription: !(
      referenceData?.has_access || referenceCollectionData?.has_access
    ),
    title: referenceCollectionData?.title,
  };
};

/**
 * TODO: ContentSmallDropdownModal (Favorite, ShareModal)
 * TODO: MediaPlayer
 */
export type GetSectionItemContentPropsProps = BaseGetSectionItemProps & {
  size: "s" | "m" | "l";
};

export const getSectionItemContentProps = ({
  item,
  itemTypeLinkSearchParams = {},
  link,
  size,
  t,
}: GetSectionItemContentPropsProps):
  | ContentSmallProps
  | ContentMediumProps
  | ContentLargeProps => {
  const referenceCollection = item?.reference?.collection;
  const referenceData = referenceCollection ?? item?.reference;
  const imageSrc = referenceData?.images;

  const title = referenceData?.name ?? referenceData?.title;
  const description =
    referenceData?.short_desc ??
    referenceData?.label_desc ??
    referenceData?.description;
  const metadata = referenceData?.lengths
    ? referenceData?.lengths
    : referenceData?.sessions
      ? referenceData?.sessions
      : null;
  const href =
    referenceCollection || item?.reference_type === "collection"
      ? `/collections/${referenceData.id}`
      : `/prayers/${referenceData.id}`;

  const props: ContentSmallProps | ContentMediumProps | ContentLargeProps = {
    asChild: link,
    children: link ? (
      <Link
        href={{
          pathname: href,
          query: itemTypeLinkSearchParams[item?.reference_type],
        }}
      />
    ) : null,
    description: description,
    imgSrc: imageSrc,
    metadata: metadata,
    subscription: !(referenceData?.has_access ?? item?.reference?.has_access),
    title: title,
  };

  if (size !== "s")
    props["metadataInlineIcon"] =
      item?.reference_type === "collection" ? (
        <Collection2Icon.Small />
      ) : undefined;

  if (item.reference_type === "radio_station") {
    props["metadata"] = t("web_radio_station");
    props["metadataInlineIcon"] = <RadioIcon />;
  }

  if (size === "l") {
    const href =
      referenceCollection || item?.reference_type === "collection"
        ? `/collections/${referenceData.id}`
        : `/prayers/${referenceData.id}`;
    props["actions"] = [
      link ? (
        <Button asChild key="links" variant="lighten">
          <Link href={href}>{t("web_details")}</Link>
        </Button>
      ) : null,
    ];
    props["color"] = referenceData?.images
      ?.color_hex as ContentLargeProps["color"];
  }

  if (size === "s")
    props["rightActions"] = item?.reference?.collection
      ? [
          <CollectionMoreMenu
            key={`${item.id}_more_menu`}
            collection={{
              id: referenceData.id,
              title,
              desc: description,
              sessions: metadata,
              has_access: referenceData.has_accesss,
            }}
            imageSrc={imageSrc}
          />,
        ]
      : [
          <PrayerMoreMenu
            key={`${item.id}_more_menu`}
            prayer={{
              id: referenceData.id,
              is_favorite: referenceData.is_favorite,
              title,
              label_desc: description,
              lengths: metadata,
              has_access: referenceData.has_access,
            }}
            imageSrc={imageSrc}
          />,
        ];

  return props;
};

export type GetSectionItemDailyQuotePropsProps = BaseGetSectionItemProps & {
  onShare: MouseEventHandler;
};

export const getSectionItemDailyQuoteProps = ({
  item,
  onShare,
}: GetSectionItemDailyQuotePropsProps): DailyQuoteProps => ({
  attribution: item?.reference?.attributed_to,
  quote: item?.reference?.text,
  actions: [
    <IconButton icon={<ShareIcon />} variant={"secondary"} onClick={onShare} />,
  ],
});

/**
 * TODO: ShareModal
 */
export type GetSectionItemTriviaPropsProps = BaseGetSectionItemProps;

export const getSectionItemTriviaProps = ({
  item,
  link,
  t,
}: GetSectionItemTriviaPropsProps): TriviaLargeProps => {
  const triviaUnstarted = item?.reference?.state === "UNSTARTED";
  const triviaFinished = item?.reference?.state === "FINISHED";

  const actions = link
    ? [
        <Button asChild key="links" variant="white">
          <Link href={"/trivia"}>
            {t(
              triviaUnstarted
                ? "web_start_trivia"
                : triviaFinished
                  ? "web_review_trivia"
                  : "resume_trivia",
            )}
          </Link>
        </Button>,
      ]
    : null;

  return {
    actions,
    centerActions: actions,
    color: mockDataTrivia.color,
    imgSrc: mockDataTrivia.imgSrc,
    summary: triviaFinished ? item?.reference?.summary : null,
    questions: !triviaUnstarted ? item?.reference?.questions : null,
  };
};

/** ITEMS */

export type GetSectionItemsProps = Pick<
  SectionProps,
  "itemTypeLinkSearchParams" | "link" | "mutate" | "section"
> & {
  analytics?: AnalyticsHook;
  t: Function;
};

export const getSectionItems = ({
  analytics,
  itemTypeLinkSearchParams,
  link,
  mutate,
  section,
  t,
}: GetSectionItemsProps) => {
  let items: KeyedElement[] | null;
  let rightActions: KeyedElement[] = [];
  let size: CarouselApi["size"] | GridProps["size"] = "m";
  const shareModal = useModal(ShareModal);
  const branch = useContext(BranchContext);
  const { query: user } = useRequestMe();

  if (section.id === 1310) return { items, rightActions, size };

  section = {
    ...section,
    items: section.items.filter(
      (item) =>
        item.reference_type !== "radio_station" &&
        !item.reference?.title?.toLowerCase().includes("radio"),
    ),
  };

  switch (section.item_type) {
    case "campaign":
      items = section.items.map((item) => (
        <CampaignLargeWithAuthor
          {...getSectionItemCampaignProps({
            analytics,
            item,
            link,
            section,
            t,
          })}
          key={item.id}
        />
      ));
      rightActions.push(
        <Button
          key="see_all"
          asChild
          onPointerDown={() => {
            analytics?.track({
              event: "Tapped Section Action Button",
              properties: {
                // TODO: is this supposed to be the id of the page being linked to?
                page_id: -1,
                section_id: section.id,
                section_title: section.title,
                section_type: section.type,
                text: section.action_title,
                link: section.action_link,
              },
            });
          }}
          size="s"
          variant="neutral"
        >
          <Link href="/pages/campaigns">{t("web_see_all")}</Link>
        </Button>,
      );
      size = "xl";
      break;
    case "daily_quote":
      items = section.items.map((item) => (
        <DailyQuote
          key={`${section.id}_${item.id}`}
          {...getSectionItemDailyQuoteProps({
            item,
            t,
            onShare: async () =>
              item?.reference?.shareable_url
                ? shareModal.show({
                    title: item?.reference?.text,
                    description: item?.reference?.attributed_to,
                    link: await branch.link({
                      feature: "Referral",
                      channel: "Referral",
                      campaign: "Daily Quote",
                      data: {
                        $canonical_identifier: `${user.data?.id ?? null}-daily-quote-${item.reference_id}`,
                        $deeplink_path: item.reference.shareable_url,
                        $fallback_url: item.reference.shareable_url,
                        $desktop_url: item.reference.shareable_url,
                      },
                    }),
                    imgSrc: item?.reference?.image_url,
                    color: "#000",
                  })
                : null,
          })}
        />
      ));
      size = "xl";
      break;
    case "deeplink_card":
    case "deeplink_card_medium":
    case "deeplink_card_small":
      let CategoryComponent: typeof CategoryLarge | typeof CategoryMedium;

      if (section.item_type === "deeplink_card") {
        CategoryComponent = CategoryLarge;
        size = "m";
      } else {
        CategoryComponent = CategoryMedium;
        size = "s";
      }

      items = section.items.map((item) => (
        <CategoryComponent
          key={`${section.id}_${item.id}`}
          {...(getSectionItemCategoryProps({
            item,
            link,
            size: section.item_type === "deeplink_card" ? "l" : "m",
            t,
          }) as CategoryMediumProps)}
        />
      ));
      break;
    case "large":
      items = section.items.map((item) => {
        if (item.reference?.available_at)
          return (
            <ChallengeLarge
              key={`${section.id}_${item.id}`}
              {...getSectionItemChallengeProps({ item, link, t, mutate })}
            />
          );

        return (
          <ContentLarge
            key={`${section.id}_${item.id}`}
            {...(getSectionItemContentProps({
              item,
              link,
              size: "l",
              t,
            }) as ContentLargeProps)}
          />
        );
      });
      size = "xl";
      break;
    case "medium":
      items = section.items.map((item) => {
        if (item.reference_type === "campaign")
          return (
            <CampaignMedium
              key={`${section.id}_${item.id}`}
              {...getSectionItemCampaignMediumProps({ item, link, t })}
            />
          );
        return (
          <ContentMedium
            key={`${section.id}_${item.id}`}
            {...(getSectionItemContentProps({
              item,
              itemTypeLinkSearchParams,
              link,
              size: "m",
              t,
            }) as ContentMediumProps)}
          />
        );
      });
      size = "s";
      break;
    case "player_card":
      items = section.items.map((item) => (
        <ChallengeLarge
          key={`${section.id}_${item.id}`}
          {...getSectionItemChallengeProps({ item, link, t, mutate })}
        />
      ));
      size = "xl";
      break;
    case "small":
      items = section.items.map((item) => (
        <ContentSmall
          key={`${section.id}_${item.id}`}
          {...(getSectionItemContentProps({
            item,
            link,
            size: "s",
            t,
          }) as ContentSmallProps)}
        />
      ));
      size = "l";
      break;
    case "trivia":
      items = section.items.map((item) => (
        <TriviaLarge
          key={`${section.id}_${item.id}`}
          {...getSectionItemTriviaProps({ item, link, t })}
        />
      ));
      size = "xl";
      break;
    default:
      break;
  }

  return { items, rightActions, size };
};

/** SECTION */

const hallowElement: HallowElement = "div";

const styles = stylex.create({
  paddingBottom: {
    paddingBottom: spacing.m,
  },
  paddingTop: {
    paddingTop: spacing.m,
  },
  rightActionsContainer: {
    alignItems: "center",
    display: "flex",
    gap: spacing.m,
  },
  stackContentSmall: {
    gap: spacing.m,
  },
  topContainer: {
    alignItems: "center",
    display: "flex",
    gap: spacing.m,
    justifyContent: "space-between",
  },
});

/**
 * TODO: Integrate MediaPlayer
 * TODO: ShareModal(s)
 */
export type SectionProps = WithAsChild<
  WithStylexArray<HallowElementProps<typeof hallowElement>>
> & {
  itemTypeLinkSearchParams?: Partial<
    Record<ReferenceType, Record<string, string>>
  >;
  link?: boolean;
  // mediaPlayerContet?: any
  mutate?: boolean;
  overrideSize?: CarouselApi["size"] | GridProps["size"];
  padding?: boolean;
  section: SectionSchemaType;
  // share?: boolean;
};

export const Section = forwardRef<any, SectionProps>(
  (
    {
      itemTypeLinkSearchParams = {},
      link = true,
      mutate = true,
      overrideSize,
      padding = true,
      section,
      ...props
    },
    ref,
  ) => {
    const analytics = useAnalytics();
    const t = useTranslations();

    if (section.title?.toLowerCase().includes("radio")) return null;

    const { items, rightActions, size } = getSectionItems({
      analytics,
      itemTypeLinkSearchParams,
      link,
      mutate,
      section,
      t,
    });
    let Layout: JSX.Element | null;

    if (items)
      switch (section.type) {
        case "carousel":
        case "stacked_carousel":
          Layout = (
            <SectionCarousel size={overrideSize ?? size}>
              {section.type === "stacked_carousel"
                ? splitArray({ array: items, size: 3 }).map(
                    (itemsSplit, index) => (
                      <Stack
                        key={`${section.id}${index}`}
                        styleXArray={[styles.stackContentSmall]}
                      >
                        {itemsSplit}
                      </Stack>
                    ),
                  )
                : items}
            </SectionCarousel>
          );
          break;
        case "grid":
          Layout = (
            <Container paddingX={padding}>
              <Grid
                size={overrideSize ?? size}
                styleXArray={[styles.paddingBottom, styles.paddingTop]}
              >
                {items.map((item, index) => (
                  <GridItem key={`${section.id}${index}`}>{item}</GridItem>
                ))}
              </Grid>
            </Container>
          );
          break;
        default:
          return null;
      }
    else return null;

    return (
      <Stack ref={ref} {...props}>
        {section.title && (
          <Container paddingX={padding} styleXArray={[styles.paddingTop]}>
            <div {...stylex.props(styles.topContainer)}>
              <Text as="h2" overflow="ellipsis" size="m" type="headline">
                {section.title}
              </Text>
              {rightActions.length > 0 && (
                <div {...stylex.props(styles.rightActionsContainer)}>
                  {rightActions}
                </div>
              )}
            </div>
          </Container>
        )}
        {Layout}
      </Stack>
    );
  },
);

Section.displayName = "Section";
