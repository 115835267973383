"use client";

import { createContext, type ReactNode, useState } from "react";
import { VisuallyHidden } from "react-aria";

import { useDynamicViewport } from "../../../../hooks";
import { VIEWPORT_TABLET } from "../../../../lib";
import type { StyleXArray } from "../../../../types";
import type { DropdownMenuProps } from "../DropdownMenu";
import { DropdownMenu } from "../DropdownMenu";
import type { ModalProps } from "../Modal";
import { Modal } from "../Modal";
import { ModalHeader } from "../ModalHeader";

export type ModalDropdownMenuContextShape = {
  close: () => void;
};

export const ModalDropdownMenuContext =
  createContext<ModalDropdownMenuContextShape>({ close: () => {} });

export type ModalDropdownMenuProps = {
  children?: ReactNode;
  contentStyleXArray?: StyleXArray;
  dropdownMenuProps?: DropdownMenuProps;
  modalProps?: ModalProps;
  trigger?: ReactNode;
  minDesktopWidth?: number;
  noCloseOnScroll?: boolean;
};

export const ModalDropdownMenu = ({
  children,
  contentStyleXArray = [],
  dropdownMenuProps = {},
  modalProps = {},
  trigger,
  minDesktopWidth = VIEWPORT_TABLET,
  noCloseOnScroll = false,
}: ModalDropdownMenuProps) => {
  const isDesktopViewport = useDynamicViewport({
    minimumWidth: minDesktopWidth,
    defaultState: null,
  });
  const [open, setOpen] = useState(false);

  if (isDesktopViewport === null) return null;

  if (isDesktopViewport)
    return (
      <ModalDropdownMenuContext.Provider
        value={{ close: () => setOpen(false) }}
      >
        <DropdownMenu
          contentStyleXArray={contentStyleXArray}
          open={open}
          onOpenChange={setOpen}
          trigger={trigger}
          noCloseOnScroll={noCloseOnScroll}
          {...dropdownMenuProps}
        >
          {dropdownMenuProps.children || children}
        </DropdownMenu>
      </ModalDropdownMenuContext.Provider>
    );

  return (
    <ModalDropdownMenuContext.Provider value={{ close: () => setOpen(false) }}>
      <Modal
        contentStyleXArray={contentStyleXArray}
        open={open}
        onOpenChange={setOpen}
        trigger={trigger}
        overlayProps={{ onClick: () => setOpen(false) }}
        {...modalProps}
      >
        {/* This exists to prevent Radix from throwing errors and breaking Storybook */}
        <VisuallyHidden>
          <ModalHeader />
        </VisuallyHidden>
        {modalProps.children || children}
      </Modal>
    </ModalDropdownMenuContext.Provider>
  );
};
