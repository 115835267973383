export enum HallowAnalyticsEvent {
  CommunityReportResponded = "Community Report Responded",
  PurchasedGiftCard = "Purchased Gift Card",
  RemovedPost = "Removed Post",
  TappedAdminDashboardTab = "Tapped Admin Dashboard Tab",
  TappedAssignAsMember = "Tapped Assign As Member",
  TappedAssignAsAdmin = "Tapped Assign As Admin",
  TappedBuyNow = "Tapped Buy Now",
  TappedCommunityMemberOptions = "Tapped Community Member Options",
  TappedCommunityMemberProfile = "Tapped Community Member Profile",
  TappedCommunityPermissionsToggle = "Tapped Community Permissions Toggle",
  TappedCommunitySettings = "Tapped Community Settings",
  TappedDashboardChartTimeframe = "Tapped Dashboard Chart Timeframe",
  TappedLeaveGroup = "Tapped Leave Group",
  TappedRemoveCommunityMember = "Tapped Remove Community Member",
  TappedRemovePost = "Tapped Remove Post",
  TappedWebAppNav = "Tapped Web App Nav",
  TappedWebCommunity = "Tapped Web Community",
  TappedWebCommunityDropdown = "Tapped Web Community Dropdown",
}
