"use client";

import NiceModal, { useModal } from "@ebay/nice-modal-react";
import type { AnalyticsEvent } from "@packages/analytics";
import { useAnalytics } from "@packages/analytics";
import { useTranslations } from "@packages/i18n";
import * as stylex from "@stylexjs/stylex";
import { useEffect } from "react";

import {
  radius,
  semanticColors,
  spacing,
  zIndices,
} from "../../../../../global/stylex/vars.stylex";
import { ShareButtons } from "../../../blocks";
import type { MaskProps } from "../../_core";
import { Mask, Text } from "../../_core";
import { HallowLogoText } from "../../icons";
import type { ModalProps } from "../Modal";
import { Modal } from "../Modal";
import { ModalHeader } from "../ModalHeader";

/**
 * Constants
 * styleX requires these to be statically defined in this file
 */
const cssMediaMinWidthTablet = "@media (min-width: 768px)";

const styles = stylex.create({
  description: {
    color: semanticColors.onColorHighest,
    textAlign: "center",
  },
  hallowLogoText: {
    color: semanticColors.onColorVeryHigh,
    height: 10,
    margin: "auto",
  },
  image: {
    "-webkit-user-drag": "none",
    borderTopLeftRadius: radius.l,
    borderTopRightRadius: radius.l,
    height: spacing.full,
    left: 0,
    objectFit: "cover",
    objectPosition: "center",
    objectRepeat: "no-repeat",
    position: "absolute",
    top: 0,
    userSelect: "none",
    width: spacing.full,
  },
  imageContainer: {
    paddingBottom: "min(86.93181818%, 300px)",
    position: "relative",
    width: spacing.full,
  },
  mask: {
    bottom: -1,

    position: "absolute",
  },
  modalContent: {
    paddingLeft: {
      [cssMediaMinWidthTablet]: spacing.l,
      default: spacing.none,
    },
    paddingRight: {
      [cssMediaMinWidthTablet]: spacing.l,
      default: spacing.none,
    },
    width: {
      [cssMediaMinWidthTablet]: spacing.fit,
      default: spacing.full,
    },
    zIndex: zIndices.modalContent,
  },
  modalHeader: {
    paddingTop: spacing.m,
  },
  shareButtons: {
    paddingBottom: spacing.s,
    paddingLeft: {
      [cssMediaMinWidthTablet]: spacing.none,
      default: spacing.m,
    },
    paddingRight: {
      [cssMediaMinWidthTablet]: spacing.none,
      default: spacing.m,
    },
    paddingTop: spacing.s,
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.m,
    paddingBottom: spacing.l,
    paddingLeft: spacing.l,
    paddingRight: spacing.l,
  },
  textContainerTop: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.t,
    maxWidth: {
      [cssMediaMinWidthTablet]: 416,
      default: "none",
    },
  },
  title: {
    color: semanticColors.onColorHighest,
    textAlign: "center",
  },
  topContainer: (props: { color: string }) => ({
    backgroundColor: props.color,
    borderRadius: radius.l,
    marginLeft: {
      [cssMediaMinWidthTablet]: spacing.none,
      default: spacing.m,
    },
    marginRight: {
      [cssMediaMinWidthTablet]: spacing.none,
      default: spacing.m,
    },
  }),
});

export type ShareModalProps = ModalProps & {
  analyticsEvent?: AnalyticsEvent;
  color: string;
  description?: string;
  imgSrc: string;
  link?: string;
  type?: "campaign";
  title: string;
};

export const ShareModal = NiceModal.create(
  ({
    analyticsEvent,
    color,
    description,
    imgSrc,
    link,
    type,
    title,
    ...props
  }: ShareModalProps) => {
    const modal = useModal();
    const analytics = useAnalytics();
    const t = useTranslations();

    useEffect(() => {
      if (analyticsEvent) {
        analytics.track(analyticsEvent);
      }
    }, []);

    return (
      <Modal
        {...props}
        overlayProps={{
          onPointerDown: () => {
            analytics.track({
              event: "Closed Share Audio View",
              properties: {
                type: type,
              },
            });
          },
        }}
        contentStyleXArray={[styles.modalContent, props.contentStyleXArray]}
        open={modal.visible}
        onOpenChange={(open) => (open ? modal.show() : modal.hide())}
      >
        <ModalHeader styleXArray={[styles.modalHeader]}>
          {t("campaign_share")}
        </ModalHeader>
        <div {...stylex.props(styles.topContainer({ color }))}>
          <div {...stylex.props(styles.imageContainer)}>
            <img alt={title} src={imgSrc} {...stylex.props(styles.image)} />
            <Mask
              color={color as MaskProps["color"]}
              direction="to top"
              styleXArray={[styles.mask]}
            />
          </div>
          <div {...stylex.props(styles.textContainer)}>
            <div {...stylex.props(styles.textContainerTop)}>
              <Text
                overflow="ellipsis"
                size="l"
                type="title"
                styleXArray={[styles.title]}
                title={title}
              >
                {title}
              </Text>
              {description && (
                <Text
                  overflow="ellipsis"
                  size="xl"
                  type="detail"
                  styleXArray={[styles.title]}
                  title={description}
                >
                  {description}
                </Text>
              )}
            </div>
            <HallowLogoText styleXArray={[styles.hallowLogoText]} />
          </div>
        </div>
        <ShareButtons
          description={description}
          link={link}
          imgSrc={imgSrc}
          title={title}
          type={type}
          {...stylex.props(styles.shareButtons)}
        />
      </Modal>
    );
  },
);
