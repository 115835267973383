"use client";

import { useRequestPrayer, useRequestTranscript } from "@packages/sdk";
import * as stylex from "@stylexjs/stylex";
import type {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  UIEvent,
} from "react";
import { useCallback, useEffect, useRef, useState } from "react";

import {
  fontSizes,
  numericPercentages,
  numericPixels,
  semanticColors,
  spacing,
} from "../../../../../global/stylex/vars.stylex";
import { ScrollArea, Text } from "../../../../components";
import type { StyleXArray, WithStylexArray } from "../../../../types";
import { ReaderThumbnail, TextSettings } from "../../blocks";

const ON_DESKTOP = "@media screen and (min-width: 768px)";

const styles = stylex.create({
  body: {
    fontFamily: "Hallow Moderat Serif, serif",
    lineHeight: 2,
    marginTop: {
      [ON_DESKTOP]: "280px",
      default: "210px",
    },
    maxWidth: "640px",
    padding: {
      [ON_DESKTOP]: `${spacing.l} ${spacing.l} ${spacing.mediaPlayerDesktop}`,
      default: `${spacing.l} ${spacing.l} ${spacing.mediaPlayerMobile}`,
    },
    transitionDuration: "250ms",
    transitionProperty: "font-size, line-height",
    transitionTimingFunction: "ease-out",
    whiteSpace: "pre-line",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    placeContent: "flex-start",
    placeItems: "center",
    position: "relative",
  },
  fontS: {
    fontSize: fontSizes.tosS,
  },
  fontM: {
    fontSize: fontSizes.tosM,
  },
  fontL: {
    fontSize: fontSizes.tosL,
  },
  immersive: {
    backgroundColor: "var(--special-color)",
  },
});

const headerStyles = stylex.create({
  default: {
    background: semanticColors.background,
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    padding: {
      [ON_DESKTOP]: "144px 0 0",
      default: "76px 0 0",
    },
    placeContent: "flex-start",
    placeItems: "center",
    pointerEvents: "none",
    position: "fixed",
    transition: "top 250ms ease-out",
    width: numericPercentages[100],
  },
  stage0: {
    top: 0,
  },
  stage1: {
    top: {
      [ON_DESKTOP]: "-84px",
      default: "-80px",
    },
  },
  stage2: {
    top: {
      [ON_DESKTOP]: "-228px",
      default: "-156px",
    },
  },
});

const maskStyles = stylex.create({
  default: {
    position: "absolute",
    transition: "top 250ms ease-out",
  },
  stage0: {
    top: {
      [ON_DESKTOP]: "258px",
      default: "192px",
    },
  },
  stage1: {
    top: {
      [ON_DESKTOP]: "174px",
      default: "112px",
    },
  },
  stage2: {
    top: {
      [ON_DESKTOP]: "32px",
      default: "36px",
    },
  },
});

const headingStyles = stylex.create({
  default: {
    marginBottom: spacing.m,
    marginTop: spacing.m,
  },
});

const thumbnailContainerStyles = stylex.create({
  default: {
    display: "flex",
    height: numericPixels[80],
    placeContent: "center",
    placeItems: "flex-end",
    width: numericPixels[80],
  },
});

export type TextOnScreenContentProps = {
  prayerId: number | null;
  audioId: number | null;
  scrollStage: 0 | 1 | 2;
  setScrollStage: Dispatch<SetStateAction<0 | 1 | 2>>;
  immersive: "immersive" | "default";
  setImmersive: Dispatch<SetStateAction<"immersive" | "default">>;
  playing: boolean;
  play: () => Promise<void>;
  isCurrentItem: boolean;
  headerProps?: WithStylexArray<
    PropsWithChildren<{
      hideSettings?: boolean;
    }>
  >;
  bodyStyleXArray?: StyleXArray;
  onAuthError?: () => void;
  onColorDetermination?: (color: string) => void;
  t: (key: string, values?: any, formats?: any) => string;
  mobileApp?: boolean;
};

export const TextOnScreenContent = ({
  prayerId,
  audioId,
  scrollStage,
  setScrollStage,
  immersive,
  setImmersive,
  playing,
  play,
  isCurrentItem,
  onAuthError,
  onColorDetermination,
  headerProps: {
    children: headerChildren,
    hideSettings,
    styleXArray: headerStyleXArray,
  } = { children: null, hideSettings: false, styleXArray: [] },
  bodyStyleXArray = [],
  t,
  mobileApp = false,
}: TextOnScreenContentProps) => {
  const [fontSize, setFontSize] = useState<"s" | "m" | "l">("m");
  const [audioIdState, setAudioIdState] = useState<number>(audioId);
  const lastScrollTop = useRef<number>(0);
  const { query: prayerQuery } = useRequestPrayer({
    id: prayerId,
    enabled: !!prayerId,
  });
  const { query: transcriptQuery } = useRequestTranscript({
    id: audioIdState,
    enabled: !!audioIdState,
  });
  const handleScroll = useCallback(
    (e: UIEvent<HTMLDivElement>) => {
      // why the typecast? Because UIEvent<T> adds `currentTarget` to the event type as T, but scroll events don't
      // have a currentTarget property
      const scrollTop = (e.target as HTMLDivElement).scrollTop ?? 0;
      if (scrollTop < lastScrollTop.current) {
        if (scrollStage > 0) {
          setScrollStage(0);
        }
      } else if (scrollTop > 400) {
        if (scrollStage < 2) {
          setScrollStage(2);
        }
      } else if (scrollTop > 75) {
        if (scrollStage !== 1) {
          setScrollStage(1);
        }
      } else {
        setScrollStage(0);
      }
      lastScrollTop.current = scrollTop;
    },
    [scrollStage],
  );

  useEffect(() => {
    if (transcriptQuery?.isPending && prayerQuery?.isSuccess) {
      setAudioIdState(prayerQuery.data?.tracks?.[0]?.id);
    }
  }, [prayerQuery?.data, transcriptQuery?.isPending]);

  useEffect(() => {
    if (transcriptQuery?.data && onColorDetermination) {
      onColorDetermination(transcriptQuery.data.images?.color_hex ?? "#171717");
    }
  }, [transcriptQuery?.data, onColorDetermination]);

  if (!audioIdState) return null;

  if (transcriptQuery.error) {
    console.error(transcriptQuery.error);
    onAuthError?.();
    return null;
  }

  if (!transcriptQuery.isSuccess) return null;

  const {
    data: { title, images, transcript },
  } = transcriptQuery;

  const thumbnail = (
    <div {...stylex.props(thumbnailContainerStyles.default)}>
      <ReaderThumbnail
        activated={isCurrentItem}
        imageSrc={images}
        playing={playing}
        scrollStage={scrollStage}
        title={title}
        onClick={play}
      />
    </div>
  );

  const body = (
    <article
      {...stylex.props(
        styles.body,
        mobileApp ? null : styles[`font${fontSize.toUpperCase()}`],
        bodyStyleXArray,
      )}
    >
      {transcript ?? t("no_transcript_available_text")}
    </article>
  );

  const headingTitle = (
    <Text
      as={"h1"}
      type={"title"}
      size={"m"}
      styleXArray={[headingStyles.default]}
    >
      {title}
    </Text>
  );

  const header = (
    <header
      {...stylex.props(
        headerStyles.default,
        immersive === "immersive" ? styles.immersive : null,
        headerStyles[`stage${scrollStage}`],
        headerStyleXArray,
      )}
    >
      {headerChildren}
      {thumbnail}
      {headingTitle}
      {hideSettings ? null : (
        <TextSettings
          fontSize={fontSize}
          setFontSize={setFontSize}
          immersive={immersive}
          setImmersive={setImmersive}
          scrollStage={scrollStage}
        />
      )}
    </header>
  );

  const content = (
    <div {...stylex.props(styles.container)}>
      {header}
      {body}
    </div>
  );

  return (
    <ScrollArea
      viewportProps={{ onScroll: handleScroll }}
      maskBottom
      maskTop
      maskBottomProps={{ size: "xl" }}
      maskTopStyleXArray={[
        maskStyles.default,
        maskStyles[`stage${scrollStage}`],
      ]}
      masksColor={
        immersive === "immersive"
          ? ((images.color_hex ?? "#171717") as `#${string}`)
          : undefined
      }
    >
      {content}
    </ScrollArea>
  );
};
